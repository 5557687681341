<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "@/router/index.js";
//import Multiselect from "vue-multiselect";
//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import Pagination from '../../../components/Pagination.vue'

//import { format } from "date-fns";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        // Pagination
        //Multiselect,
        //ckeditor: CKEditor.component,
    },
    data() {
        return {

            fields: [
                { key: "ville", label: "Ville" },
                { key: "rayon", sortable: true, label: "Rayon (Km)" },
                { key: "active", sortable: true, label: "Statut" },
                // { key: "montantAssurance", sortable: true, label: "Assurance" },
                // { key: "montantAttenteParMin", sortable: true, label: "Tarif attente", },
                { key: "action", sortable: true, label: "Action", },


            ],
            usersFields: [
                { key: "name", label: "Nom" },
                { key: "phone", sortable: true, label: "Téléphone" },
                { key: "country", sortable: true, label: "Pays" },
                // { key: "montantAssurance", sortable: true, label: "Assurance" },
                // { key: "montantAttenteParMin", sortable: true, label: "Tarif attente", },
                { key: "action", sortable: true, label: "Action", },


            ],
            cityData: [],
            showModal: false,
            city: {
                nom: "",
                longitude: null,
                latitude: null,
            },
            rayon: 0,
            areaUsers: [],
            filteredUsers: [],
            newRayonId: null,

            title: "Zone de couverture",
            items: [
                {
                    text: "Dashboard",
                },
                {
                    text: "TARIFS ET COMMISIONS",
                    active: true,
                },
            ],

            totalRows: 1,
            changeCurrent: 1,
            changePerPage: 100,
            currentPage: 1,
            currentPageD: 1,
            perPage: 100,
            perPageD: 100,
            pageOptions: [100, 250, 500],
            pageOptionsD: [100, 250, 500],
            filter: null,
            filterOn: [],
            sortBy: "orderid",
            sortDesc: false,
            newRayon: null,


        };


    },
    computed: {
        /**
         * Total no. of records
         */
        // rows() {
        //     return this.dataUser.length;
        // },
        // rowsinactif() {
        //     return this.dataUserD.length;
        // },
    },
    async mounted() {

        //this.getTarifs();
        this.getArea()
        //this.getService()

    },
    methods: {

        search() {
            if (this.filter) {
                this.filteredUsers = this.areaUsers.filter(el => el.name.toLowerCase().includes(this.filter.toLowerCase()) || el.phone.includes(this.filter))
                //this.filteredUsers
            } else {

                this.filteredUsers = []

            }

        },
        onPageChange(page) {
            console.log(page)
            this.currentPage = page;
        },

        async usersModalHandler(item) {
            this.areaUsers = []
            this.filteredUsers = []
            await this.getUsers(item.id)
            this.showModal = true




        },
        async getUsers(cityId) {
            this.areaUsers = []
            const response = await apiRequest('GET', `rtaxi-all-conducteurs/in-ville?ville_id=${cityId}`, false)
            if (response && response.status == 200) {
                console.log("users ", response.data)
                const data = response.data.map(user => {
                    //this.areaUsers.push(user)
                    return {
                        id: user.id,
                        name: user.personne.lastname + " " + user.personne.firstname,
                        country: user.personne.pays,
                        phone: user.personne.indicatifTel + user.personne.tel,
                        status: user.personne.active
                    }
                })
                console.log("data ", data)
                this.areaUsers = data
            }

        },
        goToUser(id) {
            router.push('/user/user_info/' + id)
        },
        async getArea() {
            this.cityData = []
            const response = await apiRequest('GET', 'rtaxi-all-couvertures', false)
            if (response && response.status === 200) {
                console.log("area", response.data);
                response.data.ville.forEach(el => {
                    this.cityData.push(el)
                })
                console.log("this.cityData", this.cityData)
            } else {
                console.log('fail');
                return;
            }




        },

        async addArea() {


            try {
                const response = await apiRequest('POST', 'rtaxi-couverture', { lieu: this.city, rayon: this.rayon })
                if (response && response.status === 200) {
                    console.log("area", response.data);
                    this.$toast.success("Nouvelle ville ajoutée")
                    console.log("this.cityData", this.cityData)

                    this.getArea()
                } else {
                    this.$toast.error("Un problème est survenu!")
                    console.log('fail');
                    return;
                }


            } catch (error) {
                console.log(error.response.message)
            }




        },


        // async getService() {
        //     //  messageContent
        //     const response = await apiRequest('GET', 'find-services-dash', false)
        //     if (response && response.status == 200) {
        //         //console.log("D ta up here",response .data)
        //         const sortedData = response.data.sort((a, b) => b.id - a.id);

        //         sortedData.map(el => {
        //             this.serviceData.push(el);
        //         })
        //         console.log("this service ", this.serviceData);
        //     }

        // },


        async enableArea(item) {
            //  messageContent

            console.log("enable")

            const response = await apiRequest('PUT', 'rtaxi-update-couverture', {
                id: item.id,
                active: item.active,

            })
            if (response && response.status == 200) {
                //console.log("D ta up here",response .data
                this.serviceData = []
                this.$toast.success("Modifications apportées avec succès !!!", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                this.carData = []
                await this.getService();

            } else {

                //this.editCovTarifModal = false
                this.$toast.error("Echec de la modification !!!", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });

            }

        },
        async updateRayon(zoneId) {
            //  messageContent

            console.log("enable")

            const response = await apiRequest('PUT', 'rtaxi-update-couverture', {
                id: zoneId,
                rayon: this.newRayon,

            })
            if (response && response.status == 200) {
                //console.log("D ta up here",response .data
                this.cityData = []
                this.newRayon = null
                this.newRayonId = null
                this.$toast.success("Rayon modifié avec succès !!!", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                //this.carData = []
                await this.getArea();

            } else {

                //this.editCovTarifModal = false
                this.$toast.error("Echec de la modification !!!", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });

            }

        },

        async disableArea(item) {
            //  messageContent

            this.serviceData = []


            const response = await apiRequest('PUT', 'rtaxi-update-couverture', {
                id: item.id,
                active: item.active,

            })
            if (response && response.status == 200) {
                //console.log("D ta up here",response .data

                this.$toast.success("Modifications apportées avec succès !!!", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                this.carData = []
                await this.getService();

            } else {
                //this.editCovTarifModal = false
                this.$toast.error("Echec de la modification !!!", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });

            }

        },

        getCity: function (place) {

            if (place) {
                this.city.nom = place.formatted_address;
                this.city.longitude = place.geometry.location.lng();
                this.city.latitude = place.geometry.location.lat();
            }
        },

        handleServiceChange(item) {
            console.log("Service status changed:", item);
            if (item.active) {
                this.enableArea(item);
            } else {
                this.disableArea(item);
            }
        }







    },


};

/* const dots = document.querySelector('.loaading__dots')
  const addAnimate = ()=>{
    dots.classList.add('animate')
    setTimeout(()=>{
      dots.classList.remove('aniamte');
      setTimeout(()=>{
        addAnimate()
      },100)
    }, 2600)


    }
    addAnimate(); */
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row rowBase">
            <div class="col-lg-12 ">
                <div class="card p-4 ">

                    <div class="mb-4">
                        Zone de couverture
                    </div>
                    <div class="row d-flex justify-content-start form-group">

                        <div class="col-md-4 mt-3 ">
                            <gmap-autocomplete type="text" class="form-control w-100" id="ville"
                                placeholder="Saisir une ville" @place_changed="getCity" :select-first-on-enter="true">
                            </gmap-autocomplete>
                        </div>
                        <div class="col-md-2 mt-3 d-flex "> <label for="rayon"
                                class="form-label align-self-end fs-6 mr-2 ">Rayon (Km)</label> <input id="rayon"
                                type="text" placeholder="Le rayon ici" v-model="rayon" class="form-control w-75"></div>

                        <div class="col-md-3 mt-3"> <button class="btn btn-primary"
                                :disabled="rayon == 0 || !city.latitude" @click="addArea"> Ajouter la ville</button>
                        </div>
                    </div>


                    <b-tabs pills card content-class="mt-3" justified>

                        <b-tab active class="" title=" Zones">

                            <b-table striped responsive :items="cityData" :fields="fields">
                                <template #cell(action)="row">


                                    <div class="d-flex py-2 justfy-content-center align-items-center text-center">
                                        <div class="mr-4 mt-2 " @click="usersModalHandler(row.item)">
                                            <i class="fa fa-eye fa-lg text-primary "> </i>
                                        </div>
                                        <b-form-checkbox switch v-model='row.item.active' size="lg"
                                            @change="handleServiceChange(row.item)">
                                        </b-form-checkbox>
                                    </div>
                                </template>
                                <template #cell(active)="row">


                                    <div class="flex pt-3 justfy-content-center text-left">

                                        <span v-if="row.item.active">
                                            Actif
                                        </span>
                                        <span v-else>
                                            Inactif

                                        </span>
                                    </div>
                                </template>
                                <template #cell(rayon)="row">


                                    <div class="d-flex justify-content-start pt-3 align-items-end text-left">

                                        <div v-if="newRayon === null || newRayonId !== row.item.id"> {{ row.item.rayon
                                            }}</div>
                                        <div v-if="newRayon === null || newRayonId !== row.item.id" class="mx-2"> <i
                                                class="fa fa-edit fa-lg text-primary"
                                                @click="newRayon = row.item.rayon;; newRayonId = row.item.id"></i></div>



                                        <input v-if="newRayon !== null && newRayonId === row.item.id" type="text"
                                            v-model="newRayon" class="form-control ml-1 w-25" :id="row.item.id">
                                        <button v-if="newRayon !== null && newRayonId === row.item.id"
                                            class="btn btn-primary text-white ml-1" @click="updateRayon(row.item.id)">
                                            <i class="fa fa-check"></i> </button>




                                        <button v-if="newRayon !== null && newRayonId === row.item.id"
                                            class="btn btn-primary text-white mx-2"> <i class="fa fa-x fa-lg text-white"
                                                @click="newRayon = null"></i></button>




                                    </div>
                                </template>
                                <template #cell(ville)="row">


                                    <div class="d-flex justify-content-between pt-3 align-items-end text-left">

                                        <div> {{ row.item.ville }}</div>

                                    </div>
                                </template>
                            </b-table>

                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
        <b-modal size="xl" id="my-modal" v-model="showModal" :title="'Conducteurs VTC : ' + areaUsers.length">

            <div class="row d-flex justify-content-between">

                <b-col lg="6" class="my-1">
                    <b-form-group label="Rechercher" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
                        label-size="sm" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filter-input" v-model="filter" type="search"
                                placeholder="Tapez pour rechercher" class="form-control" @input="search"></b-form-input>

                            <!-- <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
                            </b-input-group-append> -->
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
                    class="ml-2"></b-pagination>

            </div>

            <div>

                <b-table id="my-table" class="lineField" striped responsive hover
                    :items="filteredUsers.length > 0 ? filteredUsers : areaUsers" :fields="usersFields">
                    <template #cell(horraire)="data">
                        &nbsp;&nbsp;&nbsp;<div v-for="(el, index) in data.value" :key="index" class="d-inline p-2">
                            <span v-for="(sub, subIndex) in el" :key="subIndex" class="text-nowrap">
                                <span v-if="subIndex === 0">{{ sub }} ➠ </span>
                                <span v-else> {{ sub }} </span>
                            </span>
                            <span v-if="index !== data.value.length - 1"> &nbsp; <br> &nbsp;</span>
                        </div>
                    </template>
                    <template #cell(daysOfWeek)="data">

                        <div class="row d-flex justify-content-start">
                            <div class="m-0 p-0" v-for="(el, index) in data.value" :key="index">
                                <div class="m-0 p-0" v-for="(item, subIndex) in days" :key="subIndex">
                                    <div class="col mr-0 pr-1" v-if="el === item.value">{{ item.name }},
                                    </div>
                                </div>

                            </div>

                        </div>

                    </template>
                    <template #cell(phone)="row">

                        <div class="row d-flex justify-content-start">
                            <a :href="'https://wa.me/'+(row.item.phone.replace('+','')).replace(' ','') " class="lien">{{ row.item.phone }}</a>

                        </div>

                    </template>





                    <template #cell(action)="row">
                        <div @click="goToUser(row.item.id)">

                            <i class="fa fa-eye fa-lg"></i>

                        </div>
                    </template>

                </b-table>
            </div>
        </b-modal>
    </Layout>

</template>


<style>
.page-item.active .page-link {
    background-color: #3AAA35 !important;

}

.box {
    background-color: #3AAA35;
    color: white;
    border-radius: 5px;
    font-size: 1.5rem;
    padding: 20px;
    box-shadow: 5px 10px rgba(144, 238, 144, 0.417);
}

.box-tarif {
    font-weight: light;
    font-size: 1em;
}

.formule {
    font-weight: bold;
}

.detail-prix {
    font-size: 0.6em;
    font-weight: light;
}

thead {
    background: #3AAA35;
}


thead div {
    color: #fff;

}

.slide-enter-active,
.slide-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.lineField td:nth-child(5) {
    max-width: 250px !important;
    white-space: nowrap;
    overflow-x: scroll !important;
    overflow-y: auto !important;
}

.lineField td:nth-child(2) {
    max-width: 200px !important;
    white-space: nowrap;
    overflow: auto;
}


/* td{
  max-height: 100px !important;
  overflow-y:scroll !important ;
} */


/* td {
  max-width: 200px !important;
  white-space: nowrap; 
  overflow: auto;
} */
.lien{
    color:  #3AAA35 !important;
}
.bg-color-custom {
    background-color: #3AAA35 !important;
    color: white;

}

.seat-bg {
    background-color: #5bcd3e;
}

.day {
    background-color: rgb(231, 225, 225);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    /* Ajustez la taille selon vos besoins */
    height: 40px;
    border-radius: 50%;
    font-size: 14px;
}

.day:hover {

    color: #fff;
    background-color: rgb(104, 87, 131) !important;
}

.input {
    right: 13px;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 200px;
    height: 35px;
    bottom: 0;
    flex: 60%;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
    background: #ffffff;
}

.containerDot {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9edbe;
    border: 1px solid #f0c36d;
    font-size: 2rem;
    color: hsl(0, 0%, 12%);
    width: 250px;
    height: 40px;
    position: absolute;
    top: 8%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pac-container {
    z-index: 10000 !important;
}

.input input {
    padding: 3px 3px 3px 8px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    color: #495057;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
}

.input input:focus {
    border: 3px solid #3aaa35;
}

.inputCol {
    position: relative;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}

.nav-tabs .nav-link {
    background: #dc143c;
    transition: all .5s;
}

.nav-tabs .nav-link a {
    color: #fff;
}

.text-red {
    color: #dc143c;
}

.nav-tabs .nav-link a {
    color: #fff !important;
}

.text-green {
    color: #3AAA35;
}

.nav-tabs .nav-link a {
    color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
    color: blue;
    font-weight: 600 !important;
    vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
    font-weight: 600;
    background: #3AAA35;
    border-bottom: 1px solid blue;
}

.nav-tabs-custom .nav-item .nav-link::after {
    border-bottom: 1px solid #000000;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    height: 25px;
    width: 25px;
    margin: auto;
    background-color: #d5d5d5;

    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
        10px 10px 15px rgba(70, 70, 70, 0.12);
}

input[type="checkbox"]:checked {
    background-color: #5bcd3e;
}

input[type="checkbox"]:checked:after {
    display: block;

}

input[type="checkbox"]:after {
    font-weight: 600;
    font-family: FontAwesome;
    content: "\f00c";
    width: inherit;
    height: inherit;

    font-size: 20px;
    color: white;
    display: none;
}


.seat-container {
    border: 2px solid black;
    width: 35px;
    height: 35px;
    justify-content: center;
    border-radius: 5px;
    margin-right: 5px;
    /* Add margin between seats for spacing */
}

.row.d-flex .d-inline {
    display: flex;
    flex-direction: row;
}
</style>
